import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme'
import redirectImg from '../static/images/redirectImg.png'

export default function HomePage({data}) {
  return (
    <ThemeProvider theme={theme}>
       <Box className="animatedBg" sx={{ py: 1, color: "#fff"}}>
      <Typography align="center">
        Exploring Infinities is now Bhanzu. If not redirected click <a href="https://bhanzu.com"> here </a> to visit bhanzu.com
      </Typography>  
    </Box>  
    <img src={redirectImg} width='100%'/>
    <Box sx={{position:'relative', top:'-130px', left:'160px'}}>
      <Timer 
        initialSeconds={5}
      ></Timer>
    </Box>
    </ThemeProvider>
  );
}

//Timer component
const Timer = ({initialSeconds = 0}) => {
  const [seconds, setSeconds ] =  React.useState(initialSeconds);
  React.useEffect(()=>{
  let myInterval = setInterval(() => {
          if (seconds > 0) {
              setSeconds(seconds - 1);
          }
          else {
            clearInterval(myInterval)
            window.location.href = 'https://bhanzu.com';
          }
      }, 1000)
      return ()=> {
          clearInterval(myInterval);
        };
  });

  return (
      <div>
      { seconds === 0
          ? null
          :  <Typography variant='h3' >  Redirecting to bhanzu.com in ..... {seconds} seconds</Typography> 
      }
      </div>
  )
}